import { ListingBatchUpdated } from '@/notifications/types.ts';
import { useListingStore, useProductStore } from '@/stores';

const reloadMarketListings = async (event: ListingBatchUpdated) => {
  const product = useProductStore().product;

  if (!product || product.id !== event.productId) {
    return;
  }

  await useListingStore().loadMarketListings(product);
};

export default [reloadMarketListings];
