import { useApi } from '@/api';
import { useUserStore } from '@/stores';
import { Factory, Product } from '@/types';
import { captureException, withScope } from '@sentry/vue';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useCompanyFactoryStore = defineStore('companyFactory', () => {
  const api = useApi();

  const factories = ref<Factory[]>([]);

  async function load() {
    if (!useUserStore().hasPermission('CUSTOMER/FACTORY.GET')) {
      return;
    }

    await api.company
      .listFactories()
      .then((loadedFactories) => (factories.value = loadedFactories))
      .catch((error) =>
        withScope((scope) => {
          scope.setLevel('warning');
          captureException(
            new Error('Failed to load company factories', { cause: error })
          );
        })
      );
  }

  function filterByProduct(product: Product) {
    return factories.value.filter((factory) =>
      factory.products.includes(product.id)
    );
  }

  function clear() {
    factories.value = [];
  }

  return {
    clear,
    factories,
    filterByProduct,
    load,
  };
});
