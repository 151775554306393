import { Company } from '@/api/company';
import { Deal } from '@/api/deal';
import { createVesperApiClient } from '@/api/http-client';
import { LiquidityProviderListing } from '@/api/liquidity-provider-listing.ts';
import { Listing } from '@/api/listing';
import { Market } from '@/api/market';
import { Negotiation } from '@/api/negotiation';
import { PaymentTerm } from '@/api/payment-term';
import { Product } from '@/api/product';
import { Proposal } from '@/api/proposal';
import { Shipment } from '@/api/shipment';
import { StatsApi } from '@/api/stats-api';
import { Api, VesperApi } from '@/api/types';
import { User } from '@/api/user';
import { useUserStore } from '@/stores';

let api: VesperApi | null = null;

export const initializeApi = (client: Api | null = null) => {
  const instance: Api = client ?? createVesperApiClient();
  const statsApi = new StatsApi(instance);
  setInterval(() => statsApi.flush(), 5000);

  return (api = {
    company: new Company(instance),
    deal: new Deal(instance),
    listing: new Listing(instance),
    liquidityProviderListing: new LiquidityProviderListing(instance),
    market: new Market(instance),
    negotiation: new Negotiation(instance),
    paymentTerm: new PaymentTerm(instance),
    product: new Product(instance),
    proposal: new Proposal(instance),
    shipment: new Shipment(instance),
    user: new User(instance, () => useUserStore()),
    stats: statsApi,
  });
};

export function useApi(): VesperApi {
  return api ?? initializeApi();
}

export { withCancel } from './with-cancel';
