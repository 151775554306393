<template>
  <div class="flex flex-col gap-8">
    <div class="flex items-center text-sm">
      <span class="pr-3">{{ $t('login.carousel.for') }}</span>
      <span
        class="font-roboto rounded-md bg-indigo-100 px-3 py-1.5 leading-none text-indigo-600"
        >{{ $t(`login.carousel.${slideType}.name`) }}</span
      >
    </div>
    <div class="flex flex-col justify-between text-3xl/[48px]">
      <h1 class="font-bold">
        {{ $t(`login.carousel.${slideType}.title`) }}
      </h1>
      <h2 class="font-normal">
        {{ $t(`login.carousel.${slideType}.subtitle`) }}
      </h2>
    </div>
    <img
      :alt="$t(`login.carousel.${slideType}.alt-text`)"
      :src="iconSrc(slideType)"
      width="500"
    />
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

defineProps({
  slideType: {
    type: String as PropType<'traders' | 'sellers' | 'buyers'>,
    required: true,
  },
});

const iconSrc = (slideType: 'traders' | 'sellers' | 'buyers') => {
  const sources = {
    traders: 'ipad',
    sellers: 'laptop',
    buyers: 'imac',
  };

  return new URL(
    `/src/assets/images/${sources[slideType]}.jpg`,
    import.meta.url
  ).toString();
};
</script>
