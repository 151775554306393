<template>
  <DropdownList
    v-model="filterStore.filters[product.slug].specs"
    name="specs"
    :items="spec.options"
    multiple
    allow-empty
    :value-placeholder="$t(`product.main-spec.${product.slug}`)"
    by="description"
    @update:model-value="handleUpdatedSpecs($event)"
  >
    <template #default="{ item }">
      {{ item.description }}
    </template>
    <template #label>
      {{
        selected.length < 3
          ? selected.map((item) => item.description).join(', ')
          : $t(`filter.spec.count`, {
              spec: $t(`product.main-spec.${product.slug}`),
              count: selected.length,
            })
      }}
    </template>
  </DropdownList>
</template>
<script setup lang="ts">
import { useApi } from '@/api';
import DropdownList from '@/components/DropdownList.vue';
import { useSpecFilterIdentifier } from '@/composables/activity-stats.ts';
import { useFilterStore } from '@/stores';
import { Product, ProductSpec, ProductSpecOption } from '@/types';
import { computed } from 'vue';

const props = defineProps<{ product: Product; spec: ProductSpec }>();

const api = useApi();
const filterStore = useFilterStore();

const selected = computed(() => filterStore.filters[props.product.slug].specs);

const handleUpdatedSpecs = (selectedSpecs: ProductSpecOption[]) => {
  const filterId = useSpecFilterIdentifier(props.product);

  if (selectedSpecs.length === 0) {
    api.stats.collectFilterCleared(filterId);

    return;
  }

  api.stats.collectFilterApplied(
    filterId,
    selectedSpecs.map((spec) => spec.description).join(',')
  );
};
</script>
