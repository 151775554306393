import '@/extensions';
import '@/styles/index.css';
import App from '@/App.vue';
import { initialiseSentry } from '@/lib/sentry-initialiser';
import router from '@/router';
import i18n from '@/translations/plugin';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import { createApp } from 'vue';

console.log('Release', import.meta.env.VITE_RELEASE_VERSION);

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(i18n)
  .use(autoAnimatePlugin)
  .use(PrimeVue, { unstyled: true });

initialiseSentry(app, router);

app.mount('#app');
