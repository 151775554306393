<template>
  <div
    class="hover:bg-vesper-zinc flex items-center gap-6 px-4 text-xs/4"
    :class="{
      'border-b': !isLast || open,
    }"
  >
    <button
      class="flex flex-1 overflow-hidden py-3 text-left"
      :data-testid="$attrs['data-testid']"
      @click="$emit('toggle:visible')"
    >
      <Icon
        :icon="['fa-regular', open ? 'fa-minus' : 'fa-plus']"
        class="text-vesper-blue/50 mr-4 w-3"
      />

      <span class="flex-1 truncate">
        {{ group.groupKey }}
      </span>

      <span v-if="!open" class="text-vesper-blue/50">
        {{ totalSelectedOptions > 0 ? `(${totalSelectedOptions})` : '' }}
      </span>
    </button>
    <button
      v-if="open && multiple"
      type="button"
      class="text-vesper-neutral shrink-0"
      @click="$emit('toggle:selection')"
    >
      {{
        totalSelectedOptions > 0 ? $t('filter.deselect') : $t('filter.select')
      }}
    </button>
  </div>
  <Transition
    v-if="open"
    appear
    enter-active-class="transition-all duration-200 ease-out"
    enter-from-class="scale-95 opacity-0 max-h-0"
    enter-to-class="scale-100 opacity-100 max-h-96"
    leave-active-class="transition-all duration-200 ease-out"
    leave-from-class="scale-100 opacity-100 max-h-96"
    leave-to-class="scale-20 opacity-0 max-h-0"
  >
    <div
      class="grid border-b bg-transparent text-xs/none"
      :class="{ 'grid-cols-2': !singleRowOptions }"
    >
      <ComboboxOption
        v-for="value in group.groupValues"
        v-slot="{ selected, active }"
        :key="value.id"
        :value="{ value: value, groupKey: group.groupKey }"
        as="template"
      >
        <div
          class="flex cursor-pointer items-center px-6 py-2 text-xs odd:border-r"
          :class="{
            'bg-vesper-zinc': active,
          }"
        >
          <span class="mr-2">
            {{ getDisplayValue(value) }}
          </span>
          <Checkbox
            v-if="multiple"
            :checked="selected"
            class="ml-auto text-sm"
            :class="{
              'peer-checked:text-vesper-neutral': selected,
            }"
            :variant="selected ? 'solid' : 'outline'"
          />
          <Radiobutton
            v-else
            :checked="selected"
            class="ml-auto text-sm"
            :class="{
              'peer-checked:text-vesper-neutral': selected,
            }"
            :variant="selected ? 'solid' : 'outline'"
          />
        </div>
      </ComboboxOption>
    </div>
  </Transition>
</template>

<script
  setup
  lang="ts"
  generic="
    T extends Record<string, unknown> & { id: number },
    TKey extends string
  "
>
import Checkbox from '@/components/Checkbox.vue';
import Icon from '@/components/Icon.vue';
import Radiobutton from '@/components/Radiobutton.vue';
import { GroupedCollection, GroupedItem } from '@/types';
import { ComboboxOption } from '@headlessui/vue';
import { computed } from 'vue';

const props = defineProps<{
  modelValue: GroupedItem<TKey, T>[];
  group: GroupedCollection<TKey, T>;
  open: boolean;
  multiple: boolean;
  singleRowOptions: boolean;
  displayValue: 'code' | 'name' | ((item: T) => string);
  isLast: boolean;
}>();

function getDisplayValue(value: T) {
  if (typeof props.displayValue === 'function') {
    // If displayValue is a function, call it with the item value
    return props.displayValue(value);
  } else {
    // Otherwise, assume it's a string key ('code' or 'name') and return the corresponding value
    return value[props.displayValue];
  }
}

defineEmits(['toggle:visible', 'toggle:selection']);

const totalSelectedOptions = computed(() => {
  return props.modelValue.filter(
    (option) => option.groupKey === props.group.groupKey
  ).length;
});
</script>
