<template>
  <Teleport to="body">
    <Modal
      v-if="listing && deal"
      :is-open="isVisible"
      body-class="p-0 max-w-(--breakpoint-md)"
      footer-class="p-0"
      @update:is-open="dealModalStore.hideModal()"
    >
      <template #header>
        <h1 class="text-lg/none">
          {{ $t('modal-accept-negotiation.congratulations') }}
        </h1>
      </template>
      <template #body>
        <CongratulationsCard />
        <ListingInfoCard :listing="listing" />
        <DealContactInfo
          v-if="contactInfo"
          :title="$t('deals.counter-party')"
          :contact-info="contactInfo"
        />
        <TotalPrice :unit-price="deal.unitPrice" :volume="deal.volume" />
      </template>
      <template #footer>
        <div class="flex justify-end px-6 pt-4 pb-6 text-right">
          <Button variant="dark" @click="dealModalStore.hideModal()">
            <span>{{ $t('common.ok') }}</span>
          </Button>
        </div>
      </template>
    </Modal>
  </Teleport>
</template>

<script setup lang="ts">
import Button from '@/components/Button.vue';
import CongratulationsCard from '@/components/CongratulationsCard.vue';
import DealContactInfo from '@/components/DealContactInfo.vue';
import ListingInfoCard from '@/components/ListingInfoCard.vue';
import Modal from '@/components/Modal.vue';
import TotalPrice from '@/components/TotalPrice.vue';
import { useDealModalStore, useUserStore } from '@/stores';
import { ContactInfo } from '@/types';
import { computed } from 'vue';

const dealModalStore = useDealModalStore();
const userStore = useUserStore();

const isVisible = computed(() => dealModalStore.isVisible);
const deal = computed(() => dealModalStore.deal);
const listing = computed(() => dealModalStore.listing);

const contactInfo = computed<ContactInfo | null>(() => {
  if (!deal.value) {
    return null;
  }

  const acceptedBy = deal.value.acceptedBy;
  const proposedBy = deal.value.proposedBy;

  if (userStore.company?.email === acceptedBy.email) {
    return {
      name: proposedBy.name,
      email: proposedBy.email,
    };
  }

  return {
    name: acceptedBy.name,
    email: acceptedBy.email,
  };
});
</script>
