import { DeliveryRange, Incoterm, ListingType } from '@/types';

const bidIncoterms: Incoterm[] = [
  'EXW',
  'FOB',
  'CIP',
  'DPU',
  'DAP',
  'DDP',
] as const;

const offerIncoterms: Incoterm[] = ['EXW', 'FCA', 'DAP'] as const;

export function useIsAllowedIncoterm(
  type: ListingType,
  incoterm: Incoterm
): boolean {
  if (type === 'OFFER') {
    return offerIncoterms.includes(incoterm);
  }

  return bidIncoterms.includes(incoterm);
}

export function useDeliveryStartMonthIndex(
  timeSpan: DeliveryRange,
  monthEndIndex: number
): number {
  switch (timeSpan) {
    case 'MONTH':
      return monthEndIndex;
    case 'QUARTER':
      return Math.floor(monthEndIndex / 3) * 3;
    case 'HALF':
      return monthEndIndex < 6 ? 0 : 6;
  }
}
