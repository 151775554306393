import { useApi } from '@/api';
import {
  useDuplicateWithoutStats,
  useEnrichedDetail,
  useEnrichedPreview,
  useIsApprovedListing,
  useIsBidListing,
  useIsNotOngoingListing,
  useIsOfferListing,
} from '@/composables';
import { useFilterStore, useListingDetailStore } from '@/stores';
import {
  EnrichedListingDetail,
  EnrichedListingPreview,
  ListingDetail,
  ListingPreview,
  Product,
  Volume,
} from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

/**
 * @deprecated - to be split in different stores
 */
export const useListingStore = defineStore('listings', () => {
  const api = useApi();
  const listingDetailStore = useListingDetailStore();

  // Draft listings for the current logged-in user
  const draftListings = ref<EnrichedListingDetail[]>([]);

  // Relevant listings for the current logged-in user
  const filteredRelevantListings = computed(() => listingDetailStore.listings);

  // All listings that the user is current involved in (including their own)
  const activeHistory = ref<Record<number, number>>({});
  const activeListings = computed(() => {
    return filteredRelevantListings.value.filter((listing) => {
      const published = listing.status === 'PUBLISHED';
      const ongoing = listing.stats.totalOngoing > 0;
      const history = activeHistory.value[listing.id] === listing.id;

      return (listing.isYourListing && published) || ongoing || history;
    });
  });

  const opportunityListings = computed(() => {
    return filteredRelevantListings.value
      .filter(useIsApprovedListing)
      .filter(useIsNotOngoingListing)
      .filter((listing) => activeHistory.value[listing.id] == undefined)
      .sort(
        (a, b) =>
          Number(a.isByLiquidityProvider) - Number(b.isByLiquidityProvider)
      );
  });

  // All listings for the current market
  const marketListings = ref<EnrichedListingPreview[]>([]);
  const loadingMarketListings = ref(false);

  const filteredMarketListings = computed(() =>
    useFilterStore().apply(marketListings.value)
  );
  const marketBids = computed(() =>
    filteredMarketListings.value.filter(useIsBidListing)
  );
  const marketOffers = computed(() =>
    filteredMarketListings.value.filter(useIsOfferListing)
  );

  const loadDraftListings = async (product: Product) => {
    const listings = await api.listing.getDrafts(product);

    draftListings.value = listings.map((listing) => useEnrichedDetail(listing));
  };

  const loadMarketListings = async (product: Product) => {
    if (loadingMarketListings.value) {
      return;
    }

    loadingMarketListings.value = true;

    const listings = await api.listing.getPreviews(product);

    if (marketListings.value.length === 0) {
      marketListings.value = listings.map((listing) =>
        useEnrichedPreview(listing)
      );
    } else {
      updateMarketListings(listings);
    }

    loadingMarketListings.value = false;
  };

  const updateRelevantListings = (list: ListingDetail[]) => {
    list.reverse().forEach((listing) => addOrUpdateRelevantListing(listing));
  };

  const addOrUpdateRelevantListing = (listing: ListingDetail) => {
    if (listing.volume.amount <= 0) {
      /**
       * When the listing gets fulfilled, it will be removed from the store by ListingCompleted handlers.
       * Since there is no guarantee which event will be handled first, this if avoids adding to the store
       * again when the listing was previously removed.
       */
      return;
    }

    listingDetailStore.put(listing);

    computeActiveHistory();
  };

  const updateMarketListings = (list: ListingPreview[]) => {
    list.reverse().forEach((listing) => addOrUpdateMarketListing(listing));
  };

  const addOrUpdateMarketListing = (listing: ListingPreview) => {
    if (listing.availableVolume.amount <= 0) {
      return;
    }

    const index = marketListings.value.findIndex(
      (item) => item.id === listing.id
    );
    const enrichedListing = useEnrichedPreview(listing);

    index >= 0
      ? (marketListings.value[index] = enrichedListing)
      : marketListings.value.unshift(enrichedListing);
  };

  const addDraftListing = (listing: ListingDetail) => {
    const index = draftListings.value.findIndex(
      (item) => item.id === listing.id
    );

    if (index >= 0) {
      return;
    }

    draftListings.value.push(useEnrichedDetail(listing));
  };

  const computeActiveHistory = () => {
    activeListings.value.forEach((listing) => {
      activeHistory.value[listing.id] = listing.id;
    });
  };

  const completeListing = (listing: { id: number }) => {
    listingDetailStore.complete(listing);

    const marketIndex = marketListings.value.findIndex(
      (item) => item.id === listing.id
    );

    if (marketIndex >= 0) {
      const listingEntry = marketListings.value[marketIndex];

      listingEntry.status = 'COMPLETED';
      listingEntry.availableVolume.amount = 0;

      marketListings.value[marketIndex] = useEnrichedPreview(listingEntry);
    }
  };

  const cancelListing = (listing: { id: number }) => {
    listingDetailStore.cancel(listing);

    const marketIndex = marketListings.value.findIndex(
      (item) => item.id === listing.id
    );

    if (marketIndex >= 0) {
      const listingEntry = marketListings.value[marketIndex];

      listingEntry.status = 'CANCELED';

      marketListings.value[marketIndex] = useEnrichedPreview(listingEntry);
    }
  };

  const unpublishListing = (listing: { id: number }) => {
    listingDetailStore.draft(listing);
    const listingEntry = listingDetailStore.get(listing.id);

    if (listingEntry?.isYourListing) {
      remove(listing);
      addDraftListing(listingEntry);
    }

    const marketIndex = marketListings.value.findIndex(
      (item) => item.id === listing.id
    );

    if (marketIndex >= 0) {
      const listingEntry = marketListings.value[marketIndex];

      listingEntry.status = 'DRAFT';

      marketListings.value[marketIndex] = useEnrichedPreview(listingEntry);
    }
  };

  const updateListingVolume = (
    id: number,
    newVolume: Volume,
    availableVolume: Volume
  ) => {
    listingDetailStore.updateVolume(id, newVolume, availableVolume);

    const marketIndex = marketListings.value.findIndex(
      (item) => item.id === id
    );

    if (marketIndex >= 0) {
      const listingEntry = marketListings.value[marketIndex];

      listingEntry.availableVolume.amount = availableVolume.amount;

      marketListings.value[marketIndex] = useEnrichedPreview(listingEntry);
    }
  };

  const remove = (listing: { id: number }) => {
    listingDetailStore.remove(listing);

    marketListings.value = marketListings.value.filter(
      (item) => item.id !== listing.id
    );
  };

  const removeDraft = (listing: { id: number }) => {
    draftListings.value = draftListings.value.filter(
      (item) => item.id !== listing.id
    );
  };

  const removeFromActiveHistory = (listing: { id: number }) => {
    delete activeHistory.value[listing.id];
  };

  const withdraw = (listing: ListingDetail) => {
    removeFromActiveHistory(listing);

    addOrUpdateRelevantListing(useDuplicateWithoutStats(listing));
  };

  const pauseBatch = (ids: number[]) => {
    listingDetailStore.pauseBatch(ids);

    ids.forEach((id) => {
      const marketIndex = marketListings.value.findIndex(
        (item) => item.id === id
      );

      if (marketIndex >= 0) {
        const listingEntry = marketListings.value[marketIndex];

        listingEntry.status = 'PAUSED';

        marketListings.value[marketIndex] = useEnrichedPreview(listingEntry);
      }
    });
  };

  const resumeBatch = (ids: number[]) => {
    listingDetailStore.resumeBatch(ids);

    ids.forEach((id) => {
      const marketIndex = marketListings.value.findIndex(
        (item) => item.id === id
      );

      if (marketIndex >= 0) {
        const listingEntry = marketListings.value[marketIndex];

        listingEntry.status = 'PUBLISHED';

        marketListings.value[marketIndex] = useEnrichedPreview(listingEntry);
      }
    });
  };

  const isActiveListing = (listing: ListingDetail) => {
    return activeListings.value.some(
      (item: ListingDetail) => item.id === listing.id
    );
  };

  const clear = () => {
    listingDetailStore.clear();

    draftListings.value = [];
    marketListings.value = [];
    activeHistory.value = {};
  };

  return {
    activeListings,
    addDraftListing,
    addOrUpdateMarketListing,
    addOrUpdateRelevantListing,
    cancelListing,
    clear,
    completeListing,
    computeActiveHistory,
    draftListings,
    filteredMarketListings,
    isActiveListing,
    loadDraftListings,
    loadMarketListings,
    marketBids,
    marketOffers,
    opportunityListings,
    pauseBatch,
    remove,
    removeDraft,
    removeFromActiveHistory,
    resumeBatch,
    unpublishListing,
    updateListingVolume,
    updateMarketListings,
    updateRelevantListings,
    withdraw,
  };
});
