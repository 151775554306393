<!-- eslint-disable vue/v-on-function-call -->
<template>
  <div class="relative">
    <input
      :id="buttonId"
      :name="name"
      :type="type === 'password' && hidePassword === false ? 'input' : type"
      :value="modelValue"
      :placeholder="placeholder"
      class="peer text-vesper-neutral block w-full appearance-none rounded-lg border bg-transparent text-sm/none focus:outline-hidden"
      :class="[
        sizeClasses,
        errorMessage || error
          ? 'border-red-500 focus:border-red-500'
          : 'border-vesper-zinc focus:border-vesper-neutral',
      ]"
      @input="handleChange"
      @blur="handleBlur"
    />
    <span v-if="errorMessage && showError" class="text-xs text-red-500">
      {{ errorMessage }}
    </span>
    <Icon
      v-if="icon || error"
      :for="buttonId"
      width="20"
      :icon="errorMessage ? 'fa-solid fa-circle-exclamation' : icon"
      class="absolute top-4 right-6"
      :class="errorMessage ? 'text-red-500' : 'text-emerald-400'"
      :data-testid="$attrs['data-testid'] + '-icon'"
    />
    <Icon
      v-if="type === 'password'"
      :for="buttonId"
      width="20"
      :icon="hidePassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'"
      class="absolute top-4 cursor-pointer text-neutral-800/65"
      :class="[errorMessage ? 'right-14' : 'right-6']"
      @click="togglePassword()"
    />
    <label
      :for="buttonId"
      class="absolute top-2 z-10 origin-[14px] -translate-y-5 cursor-text bg-white text-sm/none font-normal duration-100 peer-placeholder-shown:text-sm/none peer-placeholder-shown:text-neutral-800/65 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:px-1 peer-focus:text-xs/none"
      :class="[
        labelClasses,
        error
          ? 'text-red-500 peer-focus:text-red-500'
          : 'text-neutral-800/65 peer-focus:text-neutral-800',
      ]"
    >
      {{ label }}
    </label>
  </div>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { useField } from 'vee-validate';
import { computed, PropType, ref, toRef } from 'vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  modelValue: {
    type: [Number, String],
    default: '',
  },
  type: {
    type: String,
    default: 'input',
  },
  variant: {
    type: String as PropType<'big' | 'small'>,
    default: 'big',
  },
  error: {
    type: Boolean,
    default: false,
  },
  showError: {
    type: Boolean,
    default: true,
  },
  icon: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validate needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const { errorMessage, handleBlur, handleChange } = useField(name, undefined, {
  syncVModel: true,
});

const buttonId = computed((): string => {
  return `floating_outlined-${props.name}`;
});

const hidePassword = ref(props.type === 'password');

const togglePassword = () => {
  hidePassword.value = !hidePassword.value;
};

const sizeClasses = computed(() => {
  return {
    big: 'px-6 py-3',
    small: 'p-1 bg-white',
  }[props.variant];
});

const labelClasses = computed(() => {
  return {
    big: 'peer-placeholder-shown:top-[34px] px-2 left-4',
    small: 'peer-placeholder-shown:top-[25px] px-1 left-2',
  }[props.variant];
});
</script>
