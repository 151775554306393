import { RouteDestination, ToastStatus } from '@/types';

export default (
  title: string,
  message: string,
  status: ToastStatus,
  destination?: RouteDestination
) => {
  const id = btoa(`${title}.${message}.${status}.${destination}`);

  return { id, title, message, status, destination };
};
