import createToast from '@/composables/create-toast';
import { Toast as ToastType, ToastPayload, ToastStatus } from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

const defaultTimeout = 2000;

export const useToastStore = defineStore('toasty', () => {
  const store = ref<Record<string, ToastType>>({});
  const toasts = computed(() => Object.values(store.value));

  const addToast = (payload: ToastPayload, status: ToastStatus) => {
    const { title, message, timeout, destination } = payload;
    const newToast = createToast(title, message, status, destination);
    store.value[newToast.id] = newToast;

    if (timeout === 0) {
      return;
    }

    setTimeout(() => removeToast(newToast.id), timeout ?? defaultTimeout);
  };

  const removeToast = (id: string) => delete store.value[id];
  const success = (payload: ToastPayload) => addToast(payload, 'SUCCESS');
  const error = (payload: ToastPayload) => addToast(payload, 'ERROR');
  const warning = (payload: ToastPayload) => addToast(payload, 'WARNING');
  const info = (payload: ToastPayload) => addToast(payload, 'INFO');
  const clear = () => (store.value = {});

  return {
    toasts,
    addToast,
    removeToast,
    clear,
    success,
    error,
    warning,
    info,
  };
});
