import { ListingDetailBatchCreated } from '@/notifications/types.ts';
import {
  useListingDetailStore,
  useProductStore,
  useToastStore,
} from '@/stores';
import { t } from 'i18next';

const reloadRelevantListings = async (event: ListingDetailBatchCreated) => {
  const product = useProductStore().product;

  if (!product || product.id !== event.productId) {
    return;
  }

  await useListingDetailStore().load(product);
};

const listingBatchCreatedNotification = (event: ListingDetailBatchCreated) => {
  const product = useProductStore().findById(event.productId);

  if (!product) {
    return;
  }

  useToastStore().success({
    title: t('notifications.listing-detail-batch-created.title', {
      count: event.listingIds.length,
      product: product.name.toLowerCase(),
    }),
    message: '',
    destination: {
      name: 'overview',
      params: {
        productSlug: product.slug,
      },
    },
    timeout: 7000,
  });
};

export default [reloadRelevantListings, listingBatchCreatedNotification];
