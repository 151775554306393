import { useIsApprovedListing, useMainSpecDescription } from '@/composables';
import addOrUpdateRelevantListing from '@/notifications/common/add-or-update-relevant-listing';
import { ListingDetailGenerated } from '@/notifications/types';
import { useListingStore, useProductStore, useToastStore } from '@/stores';
import { format } from '@/translations';
import { t } from 'i18next';

const removeFromActiveHistory = (event: ListingDetailGenerated) => {
  useListingStore().removeFromActiveHistory(event.listing);
};

const newListingFromApprovedFactoryNotification = (
  event: ListingDetailGenerated
) => {
  const listing = event.listing;

  const shouldNotify = useIsApprovedListing(listing);

  if (!shouldNotify) {
    return;
  }

  const product = useProductStore().findById(listing.productId);

  if (!product) {
    return;
  }

  useToastStore().success({
    title: t('notifications.listing-created.title', {
      type: t(`common.${listing.type}`),
    }),
    message: [
      format(listing.volume, 'volume'),
      useMainSpecDescription(listing.specifications),
      format(listing.shipment, 'shipment'),
    ].join(', '),
    destination: {
      name: 'listings',
      params: {
        productSlug: product.slug,
        id: listing.id,
      },
    },
    timeout: 7000,
  });
};

export default [
  removeFromActiveHistory,
  addOrUpdateRelevantListing<ListingDetailGenerated>,
  newListingFromApprovedFactoryNotification,
];
