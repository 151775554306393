import { useApi } from '@/api';
import {
  useShipmentFilterIdentifier,
  useSpecFilterIdentifier,
} from '@/composables/activity-stats.ts';
import { useProductStore } from '@/stores';
import { Filter, Filterable, ProductFilter } from '@/types/filter';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed } from 'vue';

/**
 * Tip: Use function instead of the object directly.
 * It must be a function that returns a new instance every time;
 * otherwise different products will share that same instance, meaning
 * changing one product also will change the other (unexpected behaviour)
 */
const empty = (): Filter => ({
  specs: [],
  shipments: [],
});

export const useFilterStore = defineStore('filter', () => {
  const api = useApi();
  const productStore = useProductStore();
  const filters = useStorage<ProductFilter>('filters', {});

  productStore.products.forEach((product) => {
    if (!filters.value[product.slug]) {
      /**
       * the property must exist before binding it to a form field
       * that said this statement ensure product filter exists before binding
       * to the form field
       */
      filters.value[product.slug] = empty();
    } else {
      // this is to ensure that if we add new filters the user will not lose the previous ones and it doesn't break
      filters.value[product.slug].specs ??= [];
      filters.value[product.slug].shipments ??= [];
    }
  });

  const hasActiveFilters = computed(() => {
    const filter = selectedProductFilter.value;
    return filter.specs.length > 0 || filter.shipments.length > 0;
  });

  const selectedProductFilter = computed((): Filter => {
    const product = productStore.product;

    return product ? filters.value[product.slug] : empty();
  });

  const apply = <T extends Filterable>(items: T[]): T[] => {
    const filter = selectedProductFilter.value;

    return sorted(
      items
        .filterProduct(productStore.product)
        .filterMainSpec(filter.specs)
        .filterShipment(filter.shipments)
    );
  };

  const sorted = <T extends Filterable>(items: T[]): T[] => {
    return items.sort((a, b) => b.id - a.id);
  };

  const sortByCreatedAt = <T extends Filterable>(items: T[]): T[] => {
    return items.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();
      return dateB - dateA;
    });
  };

  /**
   * Pushing stats to the buffer happens on the component level.
   *
   * However, when users reset the filters entirely, it does not trigger
   * an event through the component, meaning we need to handle collecting
   * the "CLEARED" stats here **as well**.
   */
  const reset = () => {
    const product = productStore.product;
    const filterState = filters.value[product.slug];

    if (filterState.specs.length > 0) {
      api.stats.collectFilterCleared(useSpecFilterIdentifier(product));
    }

    if (filterState.shipments.length > 0) {
      api.stats.collectFilterCleared(useShipmentFilterIdentifier(product));
    }

    filters.value[productStore.product.slug] = empty();
  };

  return {
    apply,
    sorted,
    sortByCreatedAt,
    filters,
    hasActiveFilters,
    reset,
    selectedProductFilter,
  };
});
