import { Api } from '@/api/types';
import {
  CreateListingRequest,
  CreateListingResponse,
  ListingDetail,
  ListingPreview,
  PatchListingRequest,
  Product,
  Volume,
} from '@/types';

export class Listing {
  public constructor(protected readonly client: Api) {}

  public getPreviews(product: Product): Promise<ListingPreview[]> {
    return this.client.get(`/marketplace/products/${product.id}/listings`);
  }

  public getRelevant(product: Product): Promise<ListingDetail[]> {
    return this.client.get(
      `/marketplace/products/${product.id}/listings/relevant`
    );
  }

  public getYourListings(): Promise<ListingDetail[]> {
    return this.client.get('/marketplace/company/listings');
  }

  public getDrafts(product: Product): Promise<ListingDetail[]> {
    return this.client.get(
      `/marketplace/products/${product.id}/listings/draft`
    );
  }

  public cancel(id: number): Promise<void> {
    return this.client.delete(`/marketplace/company/listings/${id}`);
  }

  public unpublish(id: number): Promise<void> {
    return this.client.post(`/marketplace/company/listings/${id}/unpublish`);
  }

  public getDetailById(id: number): Promise<ListingDetail> {
    return this.client.get(`/marketplace/listings/${id}/detail`);
  }

  public create(listing: CreateListingRequest): Promise<CreateListingResponse> {
    return this.client.post('/marketplace/company/listings', listing, {
      headers: {
        'X-Request-ID': listing.uuid,
      },
    });
  }

  public patch(listing: PatchListingRequest): Promise<void> {
    return this.client.patch(
      `/marketplace/company/listings/${listing.id}`,
      listing
    );
  }

  public updateVolume(id: number, newVolume: Volume): Promise<void> {
    return this.client.patch(`/marketplace/company/listings/${id}/volume`, {
      volume: newVolume,
    });
  }

  public accept(id: number): Promise<void> {
    return this.client.post(`/marketplace/listings/${id}/accept`);
  }
}
