<template>
  <GroupMultiSelect
    v-if="incotermGroups"
    name="selectedFactories"
    :model-value="filterStore.filters[props.product.slug].shipments"
    :grouped-data="incotermGroups"
    display-value="name"
    label="incoterm"
    variant="small"
    :single-row-options="false"
    @update:model-value="handleUpdatedShipments($event)"
  />
</template>

<script setup lang="ts">
import { useApi } from '@/api';
import GroupMultiSelect from '@/components/GroupMultiSelect.vue';
import { useShipmentFilterIdentifier } from '@/composables/activity-stats.ts';
import { useFilterStore, useShipmentStore } from '@/stores';
import { Product, ShipmentFilterOption } from '@/types';
import { computedAsync } from '@vueuse/core';

defineEmits(['toggle:visible', 'toggle:selection']);
const props = defineProps<{ product: Product }>();

const api = useApi();
const filterStore = useFilterStore();

const shipmentStore = useShipmentStore();
const incotermGroups = computedAsync(() => shipmentStore.incotermGroups);

const handleUpdatedShipments = (selectedShipments: ShipmentFilterOption[]) => {
  filterStore.filters[props.product.slug].shipments = selectedShipments;
  const filterId = useShipmentFilterIdentifier(props.product);

  if (selectedShipments.length === 0) {
    api.stats.collectFilterCleared(filterId);

    return;
  }

  api.stats.collectFilterApplied(
    filterId,
    selectedShipments
      .map((shipment) => `${shipment.groupKey}-${shipment.value.name}`)
      .join(',')
  );
};
</script>
