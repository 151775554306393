import generalListingBatchCreatedHandlers from '@/notifications/general/general-listing-batch-created-handlers.ts';
import generalListingBatchPausedHandlers from '@/notifications/general/general-listing-batch-paused-handlers.ts';
import generalListingBatchResumedHandlers from '@/notifications/general/general-listing-batch-resumed-handlers.ts';
import generalListingBatchUpdatedHandlers from '@/notifications/general/general-listing-batch-updated-handlers.ts';
import generalListingCanceledHandlers from '@/notifications/general/general-listing-canceled-handlers';
import generalListingCompletedHandlers from '@/notifications/general/general-listing-completed-handlers';
import generalListingPublishedHandlers from '@/notifications/general/general-listing-published-handlers';
import generalListingUnpublishedHandlers from '@/notifications/general/general-listing-unpublished-handlers';
import generalListingVolumeUpdatedHandlers from '@/notifications/general/general-listing-volume-updated-handlers.ts';
import generalMarketDealPreviewCreated from '@/notifications/general/general-market-deal-preview-created-handlers';
import generalReloadStoreHandler from '@/notifications/general/general-reload-store-handler';
import { EventHandler } from '@/types/notification';

export const generalEventsHandlers: Record<string, EventHandler[]> = {
  'Backoffice.ReloadStore': generalReloadStoreHandler,
  'Marketplace.ListingBatchCreated': generalListingBatchCreatedHandlers,
  'Marketplace.ListingBatchUpdated': generalListingBatchUpdatedHandlers,
  'Marketplace.ListingBatchPaused': generalListingBatchPausedHandlers,
  'Marketplace.ListingBatchResumed': generalListingBatchResumedHandlers,
  'Marketplace.ListingCanceled': generalListingCanceledHandlers,
  'Marketplace.ListingCompleted': generalListingCompletedHandlers,
  'Marketplace.ListingPublished': generalListingPublishedHandlers,
  'Marketplace.ListingUnpublished': generalListingUnpublishedHandlers,
  'Marketplace.ListingVolumeUpdated': generalListingVolumeUpdatedHandlers,
  'Marketplace.MarketDealPreviewCreated': generalMarketDealPreviewCreated,
};
