<template>
  <h2 class="mb-4 text-xl/6 font-medium">{{ product.name }}</h2>
  <div class="grid grid-cols-3 gap-4">
    <ListingInfoCardBlock
      :tag="$t('listing-card.price-mt')"
      :value="`€${form.unitPriceValue ?? ''}`"
      variant="big"
    />
    <ListingInfoCardBlock
      :tag="$t('listing-card.volume')"
      :value="`${form.volumeAmount ?? ''} MT`"
      variant="big"
    />
  </div>
  <hr class="my-4 h-px bg-slate-200" />
  <CreateListingPreviewFactoryBanner :form="form" />
  <hr class="my-4 h-px bg-slate-200" />
  <div class="grid grid-cols-3 gap-4">
    <ListingInfoCardBlock
      :tag="$t(`product.main-spec.${product.slug}`)"
      :value="product.slug === 'skimmed-milk-powder' ? heat : type"
    />
    <ListingInfoCardBlock :tag="$t('listing-card.age')" :value="age" />
  </div>
  <hr class="my-4 h-px bg-slate-200" />
  <div class="grid grid-cols-3 gap-4">
    <template v-for="spec in form.specs.slice(2)" :key="spec.description">
      <ListingInfoCardBlock
        :tag="String(spec.description)"
        :value="spec.options.map((option) => option.description).join(', ')"
      />
    </template>
  </div>
  <hr class="my-4 h-px bg-slate-200" />
  <div class="grid grid-cols-3 gap-4">
    <ListingInfoCardBlock
      :tag="$t('listing.shipment.incoterm')"
      :value="form.incoterm ?? ''"
    />
    <ListingInfoCardBlock
      :tag="$t('listing.shipment.delivery-location')"
      :value="form.deliveryLocation?.name ?? ''"
    />
    <ListingInfoCardBlock
      :tag="$t('listing.shipment.latest-delivery')"
      :value="form.delivery ? $format(form.delivery, 'delivery') : '-'"
    />
  </div>
</template>

<script setup lang="ts">
import CreateListingPreviewFactoryBanner from '@/components/CreateListingPreviewFactoryBanner.vue';
import ListingInfoCardBlock from '@/components/ListingInfoCardBlock.vue';
import { previewDescription } from '@/data/formatters';
import { ListingForm, Product } from '@/types';
import { computed, PropType } from 'vue';

const props = defineProps({
  form: {
    type: Object as PropType<ListingForm>,
    required: true,
  },
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
});

const selectedSpecDescription = (label: string) => {
  const selected = props.form.specs.filter((selectedSpec) => {
    return selectedSpec.description.includes(label);
  });

  if (selected.length > 0 && selected[0]?.options?.length > 0) {
    return previewDescription(selected[0]?.options[0]?.description);
  }

  return '-';
};

const heat = computed(() => selectedSpecDescription('Heat'));
const type = computed(() => selectedSpecDescription('Type'));
const age = computed(() => selectedSpecDescription('Product age'));

defineEmits(['submit']);
</script>
