<template>
  <button
    class="group flex items-center text-sm"
    @click="panelCreateListingStore.openPanel()"
  >
    <div
      class="bg-vesper-neutral relative flex size-7 shrink-0 flex-col items-center justify-center overflow-hidden rounded-md text-white"
      :class="{
        'mr-[23px]': showSidebar,
      }"
    >
      <Icon
        icon="fa-regular fa-plus"
        fixed-width
        class="translate-y-[7px] duration-200 ease-out group-hover:-translate-y-3.5"
      />
      <Icon
        icon="fa-regular fa-plus"
        fixed-width
        class="text-vesper-green translate-y-3.5 duration-200 ease-out group-hover:translate-y-[-7px]"
      />
    </div>
    <div
      v-if="showSidebar"
      data-test="menu-item-text"
      class="text-vesper-blue/50 group-hover:text-vesper-purple"
    >
      {{
        $t('common.create-order', { companyTypes: userStore.company?.types })
      }}
    </div>
  </button>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { usePanelCreateListingStore, useUserStore } from '@/stores';

defineProps({
  showSidebar: {
    type: Boolean,
    required: true,
  },
});

const panelCreateListingStore = usePanelCreateListingStore();
const userStore = useUserStore();
</script>
