import { useProductStore } from '@/stores/product';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useNotificationStore = defineStore('notifications', () => {
  const productStore = useProductStore();
  const productActivity = ref<Record<number, boolean>>({});

  const addActivityIndicator = (id: number) => {
    if (productStore.product.id === id) {
      // do not add indicator if product is currently selected
      return;
    }

    productActivity.value[id] = true;
  };
  const removeActivityIndicator = (id: number) => {
    productActivity.value[id] = false;
  };

  return {
    productActivity,
    addActivityIndicator,
    removeActivityIndicator,
  };
});
