import { useProductStore } from './product';
import { useApi } from '@/api';
import { Market, MarketStatus } from '@/types/market';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useMarketStore = defineStore('market', () => {
  const api = useApi();
  const productStore = useProductStore();

  const markets = ref<Market[]>([]);

  const closedMarket = computed(() => getActiveProductMarket('FINALISED'));

  const openMarket = computed(() => getActiveProductMarket('OPEN'));

  const nextMarket = computed(() => getActiveProductMarket('SCHEDULED'));

  const activeMarket = computed(() =>
    openMarket.value ? openMarket.value : nextMarket.value
  );

  const getActiveProductMarket = (status: MarketStatus) => {
    const productId = productStore.product?.id;

    return (
      markets.value.find(
        (market) => market.productId === productId && market.status === status
      ) || null
    );
  };

  async function load() {
    markets.value = await api.market.list();
  }

  return {
    activeMarket,
    closedMarket,
    load,
    markets,
    nextMarket,
  };
});
