<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div
    v-if="sidebarPinned && !lg"
    class="bg-vesper-stone fixed inset-0 z-10 opacity-50"
    @click="toggleSidebar()"
  ></div>
  <aside
    class="sidebar-bounce left-0 flex shrink-0 flex-col overflow-x-hidden bg-slate-200 whitespace-nowrap transition-[width] duration-300"
    :class="[
      sidebarWidth,
      {
        'lg:static': sidebarPinned,
        'fixed z-20 h-full': !sidebarPinned || !lg,
      },
    ]"
    data-testid="app-sidebar"
    @mouseenter="sidebarHover = true"
    @mouseleave="sidebarHover = false"
  >
    <div class="border-b border-slate-300 py-[26px] pl-[22px]">
      <div class="flex">
        <VesperLogo
          type="STAR"
          alt-text="Vesper Star"
          styling="h-5 w-7 shrink-0"
        />
        <VesperLogo
          v-if="showSidebar"
          type="TEXT"
          alt-text="Vesper Text"
          styling="ml-[22px] h-5"
        />
      </div>
    </div>
    <nav class="flex h-full flex-col">
      <div class="flex flex-col gap-y-4">
        <div
          class="border-vesper-blue/5 flex flex-col gap-y-4 border-b px-[22px] py-4"
        >
          <MenuItem
            v-for="product in productStore.products"
            :key="product.slug"
            :show-sidebar="showSidebar"
            :icon-left="useGetIcon(product.slug)"
            :to="{ name: 'overview', params: { productSlug: product.slug } }"
          >
            <template #icon>
              <NotificationIndicator
                :show="notificationStore.productActivity[product.id] ?? false"
                class="-top-1.5 -right-2 animate-bounce"
              >
                <Icon :icon="useGetIcon(product.slug)" fixed-width />
              </NotificationIndicator>
            </template>

            {{ $t(`pages.${product.slug}`) }}
          </MenuItem>
        </div>
        <div class="flex flex-col gap-y-4 px-[22px]">
          <template v-for="item in mainNavItems" :key="item.key">
            <MenuItem
              v-if="item.accessible ?? true"
              :show-sidebar="showSidebar"
              :icon-left="item.icon"
              :to="item.to"
            >
              {{ $t(`pages.${item.key}`) }}
            </MenuItem>
          </template>

          <CreateOrderMenuItem :show-sidebar="showSidebar" />
        </div>
      </div>
      <div class="mt-auto flex flex-col gap-y-4 px-[22px] py-4">
        <div class="group flex items-center">
          <a
            href="https://knowledge.marketplace.vespertool.com/"
            target="_blank"
            class="relative flex w-full items-center text-sm"
          >
            <div
              class="relative flex size-7 shrink-0 items-center justify-center rounded-md text-indigo-950 group-hover:bg-slate-300"
              :class="{
                'mr-[23px]': showSidebar || sidebarHover,
              }"
            >
              <Icon icon="fa-regular fa-book-open-cover" fixed-width />
            </div>

            <div
              v-if="sidebarHover || showSidebar"
              class="text-vesper-blue/50 group-hover:text-indigo-950"
            >
              {{ $t(`pages.knowledge-base`) }}
            </div>
          </a>
        </div>
        <MenuItem
          v-for="item in supportNavItems"
          :key="item.key"
          :show-sidebar="showSidebar"
          :icon-left="item.icon"
          :to="item.to"
        >
          {{ $t(`pages.${item.key}`) }}
        </MenuItem>
        <div class="group flex items-center">
          <a
            href="https://app.vespertool.com/"
            target="_blank"
            class="relative flex w-full items-center text-sm"
          >
            <div
              class="relative flex size-7 shrink-0 items-center justify-center rounded-md text-indigo-950 group-hover:bg-slate-300"
              :class="{
                'mr-[23px]': showSidebar || sidebarHover,
              }"
            >
              <Icon
                icon="fa-regular fa-arrow-up-right-from-square"
                fixed-width
              />
            </div>

            <div
              v-if="sidebarHover || showSidebar"
              class="text-vesper-blue/50 group-hover:text-indigo-950"
            >
              {{ $t(`pages.vesper-tool`) }}
            </div>
          </a>
        </div>
        <ReportABugButton
          :sidebar-hover="sidebarHover"
          :show-sidebar="showSidebar"
        />
      </div>
    </nav>
    <div class="border-vesper-blue/5 mt-auto flex border-t px-[22px]">
      <button
        class="group flex items-center py-[17px]"
        @click="toggleSidebar()"
      >
        <div
          class="mr-[19px] flex size-7 shrink-0 items-center justify-center rounded-md group-hover:bg-slate-300"
          :class="{
            'mr-[23px]': showSidebar || sidebarHover,
          }"
        >
          <Icon
            :icon="
              sidebarPinned
                ? 'fa-regular fa-angle-left'
                : 'fa-regular fa-angle-right'
            "
            alt="Toggle menu icon"
            fixed-width
            class="text-sm text-indigo-950"
          />
        </div>
        <div
          v-if="showSidebar"
          class="text-vesper-blue/50 text-sm group-hover:text-indigo-950"
        >
          {{ sidebarPinned ? $t(`common.collapse`) : $t(`common.expand`) }}
        </div>
      </button>
    </div>
  </aside>
  <CreateListingPanel />
</template>

<script setup lang="ts">
import CreateListingPanel from '@/components/CreateListingPanel.vue';
import CreateOrderMenuItem from '@/components/CreateOrderMenuItem.vue';
import Icon from '@/components/Icon.vue';
import MenuItem from '@/components/MenuItem.vue';
import NotificationIndicator from '@/components/NotificationIndicator.vue';
import ReportABugButton from '@/components/ReportABugButton.vue';
import VesperLogo from '@/components/VesperLogo.vue';
import { useGetIcon } from '@/composables';
import {
  useNotificationStore,
  useProductStore,
  useSidebarStore,
  useUserStore,
} from '@/stores';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { LocationAsRelativeRaw, useRoute } from 'vue-router';

const sidebarStore = useSidebarStore();
const productStore = useProductStore();
const notificationStore = useNotificationStore();
const userStore = useUserStore();

productStore.load();

const { lg } = useBreakpoints(breakpointsTailwind);

watch(
  () => lg.value,
  () => {
    if (!lg.value && sidebarPinned.value) {
      sidebarHover.value = false;
      sidebarStore.sidebarPinned = false;
    }
  }
);
const route = useRoute();

watch(
  () => route.params.productSlug,
  () => {
    const product =
      productStore.findBySlug(route.params.productSlug) ?? productStore.product;

    if (product) {
      productStore.setActiveProduct(product);
    }
  }
);

type MenuItemConfig = {
  icon?: string;
  key: string;
  to: LocationAsRelativeRaw;
  accessible?: boolean;
};

const mainNavItems: MenuItemConfig[] = [
  {
    icon: 'fa-duotone fa-solid fa-clipboard-list',
    key: 'your-listings',
    to: { name: 'your-listings' },
  },
  {
    icon: 'fa-regular fa-arrow-right-arrow-left',
    key: 'your-deals',
    to: { name: 'your-deals' },
  },
  {
    icon: 'fa-duotone fa-solid fa-rectangle-history-circle-plus ',
    key: 'liquidity-provider',
    to: { name: 'liquidity-provider' },
    accessible: userStore.isLiquidityProvider(),
  },
];

const supportNavItems: MenuItemConfig[] = [
  {
    icon: 'fa-regular fa-user',
    key: 'your-account',
    to: { name: 'account-details' },
  },
];

const sidebarHover = ref(false);
const sidebarPinned = computed(() => sidebarStore.sidebarPinned);
const showSidebar = computed(() => sidebarPinned.value || sidebarHover.value);

function toggleSidebar() {
  if (sidebarPinned.value) {
    sidebarHover.value = false;
    sidebarStore.sidebarPinned = false;
  } else {
    sidebarStore.sidebarPinned = true;
  }

  localStorage.setItem(
    'sidebar-pinned',
    sidebarPinned.value ? 'true' : 'false'
  );
}

const sidebarWidth = computed(() => {
  return showSidebar.value ? 'w-[233px]' : 'w-[72px]';
});
</script>

<style scoped>
.sidebar-bounce {
  transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1.5);
}
</style>
