<template>
  <Component
    :is="component"
    class="flex flex-row items-center justify-between"
    :class="[variant, { big: big }]"
    :type="component === 'button' ? type : null"
    :disabled="disabled || loading"
  >
    <div>
      <Icon
        v-if="iconPosition === 'left' && (loading || icon)"
        :icon="loading ? 'spinner' : icon"
        :spin="loading"
        :class="{ 'mr-2': !!$slots.default, 'animate-spin': loading }"
      />
    </div>
    <slot />
    <div>
      <Icon
        v-if="iconPosition === 'right' && (loading || icon)"
        :icon="loading ? 'spinner' : icon"
        :class="{ 'ml-2': !!$slots.default, 'animate-spin': loading }"
      />
    </div>
  </Component>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed, PropType, useAttrs } from 'vue';

const attrs = useAttrs();

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String as PropType<
      | 'primary'
      | 'secondary'
      | 'link'
      | 'danger'
      | 'warning'
      | 'cta'
      | 'dark'
      | 'light'
    >,
    default: 'primary',
  },
  big: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<'button' | 'submit'>,
    default: 'button',
  },
  icon: {
    type: [Object, Array, String] as PropType<
      (typeof FontAwesomeIcon)['$props']['icon']
    >,
    required: false,
    default: '',
  },
  iconPosition: {
    type: String as PropType<'left' | 'right'>,
    default: 'left',
  },
});

const component = computed(() => {
  if (props.disabled) {
    return 'button';
  }
  if (attrs.to) {
    return 'router-link';
  }
  if (attrs.href) {
    return 'a';
  }
  return 'button';
});
</script>

<style scoped>
@reference '@/styles/index.css';

button,
a {
  @apply font-roboto rounded-md px-3 py-2 text-sm font-medium transition;
  @apply focus:outline-hidden;
  @apply hover:ring-4;
  @apply disabled:cursor-not-allowed disabled:ring-0;
}

.primary {
  @apply bg-vesper-turquoise ring-vesper-turquoise/25 text-white;
  @apply disabled:bg-vesper-turquoise/30;
}

.secondary {
  @apply text-vesper-blue hover:border-vesper-blue ring-vesper-blue/25 border border-gray-300 font-normal hover:ring-0;
  @apply disabled:opacity-50;
}

.danger {
  @apply bg-linear-to-r from-red-500 to-red-600 text-white ring-red-700/25;
  @apply disabled:bg-red-700/30;
}

.warning {
  @apply text-vesper-neutral rounded-2xl bg-amber-200;
}

.link {
  @apply text-vesper-accent px-0;
  @apply hover:underline hover:ring-0;
  @apply disabled:text-vesper-accent/50;
}

.cta {
  @apply border-vesper-turquoise from-vesper-turquoise border bg-linear-to-r to-[#27A7CC] px-6 text-white;
  @apply disabled:opacity-50;
  @apply hover:from-vesper-turquoise hover:to-vesper-turquoise hover:animate-[cta_3s_linear_infinite] hover:via-[#27A7CC] hover:bg-[length:600%] hover:ring-0;
}

.dark {
  @apply bg-vesper-neutral ring-vesper-neutral/25 border border-transparent text-white;
  @apply disabled:opacity-50;
}

.light {
  @apply border-vesper-zinc ring-vesper-zinc/25 border bg-white;
  @apply disabled:opacity-50;
}

.big {
  @apply px-6 py-4 leading-none;
}

@keyframes cta {
  0% {
    background-position: left center;
  }
  50% {
    background-position: right center;
  }
  100% {
    background-position: left center;
  }
}
</style>
