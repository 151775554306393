import { useComputedAsyncState } from '@/composables/computed-async-state';
import { useIsBidListing } from '@/composables/listing';
import { usePaymentTermStore, useUserStore } from '@/stores';
import { EnrichedListingDetail, Owner } from '@/types';

export function usePaymentTermDuration(
  listing: EnrichedListingDetail,
  counterParty: Owner
): number | null {
  const paymentTermStore = usePaymentTermStore();
  const userStore = useUserStore();

  // As a buyer, I want to see MY payment terms when interacting with offers, as only buyers can set payment terms.
  const paymentTermOwner =
    !listing.isYourListing && useIsBidListing(listing)
      ? counterParty
      : userStore.company;

  const initialState = paymentTermOwner
    ? (paymentTermStore.find(paymentTermOwner)?.duration ?? null)
    : null;

  const { state: duration } = useComputedAsyncState(
    async () => {
      if (!userStore.company || !paymentTermOwner) {
        return null;
      }

      await paymentTermStore.loadDefaultBy(paymentTermOwner);

      return paymentTermStore.find(paymentTermOwner)?.duration ?? null;
    },
    initialState,
    { immediate: !!userStore.company }
  );

  return duration.value;
}
