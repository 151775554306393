import { LiquidityDeal, Product, ProductId } from '@/types';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLiquidityDealStore = defineStore('liquidity-deal', () => {
  const isQueuing = ref(false);
  const queue = ref<Record<ProductId, LiquidityDeal[]>>({});

  const enqueue = (deal: LiquidityDeal) => {
    if (!isQueuing.value) {
      return;
    }

    if (!queue.value[deal.productId]) {
      queue.value[deal.productId] = [deal];
    } else {
      queue.value[deal.productId].push(deal);
    }
  };

  const dequeueAll = (product: Product) => {
    const length = queue.value[product.id]?.length ?? 0;

    return queue.value[product.id]?.splice(0, length) ?? [];
  };

  const reset = () => {
    stopQueuing();
    queue.value = {};
  };

  const startQueuing = () => {
    isQueuing.value = true;
  };

  const stopQueuing = () => {
    isQueuing.value = false;
  };

  return {
    queue,
    dequeueAll,
    enqueue,
    reset,
    startQueuing,
    stopQueuing,
    isQueuing,
  };
});
