import { Api } from '@/api/types';
import {
  Market as MarketResponse,
  MarketActivity,
  MarketState,
} from '@/types/market';

export class Market {
  public constructor(protected readonly client: Api) {}

  public list(): Promise<MarketResponse[]> {
    return this.client.get(`/marketplace/markets`);
  }

  public getMarketSummary(market: MarketResponse): Promise<MarketState> {
    return this.client.get(`/marketplace/markets/${market.id}/summary`);
  }

  public getMarketActivity(market: MarketResponse): Promise<MarketActivity> {
    return this.client.get(`/marketplace/markets/${market.id}/activity`);
  }
}
