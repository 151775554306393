import { Api } from '@/api/types';
import {
  CreateLiquidityProviderListing,
  DateGroupedLiquidityProviderListing,
  Product,
  UpdateLiquidityProviderListing,
} from '@/types';

export class LiquidityProviderListing {
  public constructor(protected readonly client: Api) {}

  public getLiquidityListings(
    product: Product
  ): Promise<DateGroupedLiquidityProviderListing[]> {
    return this.client.get(
      `/marketplace/products/${product.id}/liquidity-listings`
    );
  }

  public updateBatch(
    listings: UpdateLiquidityProviderListing[]
  ): Promise<void> {
    return this.client.patch(
      `/marketplace/company/listings/batch-liquidity`,
      listings
    );
  }

  public createBatch(
    listings: CreateLiquidityProviderListing[]
  ): Promise<void> {
    return this.client.post(
      '/marketplace/company/listings/batch-liquidity',
      listings
    );
  }

  public pauseAll(productId: number): Promise<void> {
    return this.client.post('/marketplace/company/listings/liquidity/pause', {
      productId,
    });
  }

  public resumeAll(productId: number): Promise<void> {
    return this.client.post('/marketplace/company/listings/liquidity/resume', {
      productId,
    });
  }
}
