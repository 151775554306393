import mainSpec from '@/filters/filterable/main-spec';
import selectedProduct from '@/filters/filterable/selected-product';
import shipment from '@/filters/filterable/shipment';
import {
  Filterable,
  Product,
  ProductSpecOption,
  ShipmentFilter,
} from '@/types';

Array.prototype.filterProduct = function <T extends Filterable>(
  product: Product
): T[] {
  return this.filter((item: Filterable) => selectedProduct(item, product));
};

Array.prototype.filterMainSpec = function <T extends Filterable>(
  specs: ProductSpecOption[]
): T[] {
  const selected = specs.map((option) => option.description);

  return this.filter((item) => mainSpec(item, selected));
};

Array.prototype.filterShipment = function <T extends Filterable>(
  shipments: ShipmentFilter[]
): T[] {
  const selected = shipments.map((shipment) => {
    return `${shipment.groupKey} ${shipment.value.name}`;
  });

  return this.filter((item) => shipment(item, selected));
};
