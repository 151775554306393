import { EnrichedListingDetail } from '@/types';
import { DealDetail } from '@/types/deal';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDealModalStore = defineStore('modal', () => {
  const isVisible = ref(false);
  const deal = ref<DealDetail | null>(null);
  const listing = ref<EnrichedListingDetail | null>(null);

  const showModal = (
    companyDeal: DealDetail,
    relatedListing: EnrichedListingDetail
  ) => {
    deal.value = companyDeal;
    listing.value = relatedListing;
    isVisible.value = true;
  };

  const hideModal = () => {
    isVisible.value = false;
    deal.value = null;
    listing.value = null;
  };

  return {
    isVisible,
    deal,
    listing,
    showModal,
    hideModal,
  };
});
