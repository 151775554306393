<template>
  <Transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="opacity-0 translate-y-full"
    enter-to-class="opacity-100 translate-y-0"
    leave-active-class="transition ease-in duration-300"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 translate-y-full"
    appear
  >
    <div
      v-if="!network.isOnline"
      class="border-vesper-zinc bg-vesper-offwhite fixed right-8 bottom-4 rounded-xl border p-10 text-center shadow-2xl"
    >
      <Icon
        icon="fa-duotone fa-solid fa-wifi-slash"
        class="mb-6 animate-pulse text-3xl"
      />
      <h2 class="font-medium">{{ $t('offline-indicator.title') }}</h2>
      <p class="text-vesper-neutral/60">
        {{ $t('offline-indicator.message') }}
      </p>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { useNetwork } from '@vueuse/core';
import { reactive } from 'vue';

const network = reactive(useNetwork());
</script>
