import { useFilterStore } from '@/stores';
import { DealDetail, DealId, DealPreview, ListingDetail } from '@/types';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useDealStore = defineStore('deals', () => {
  const filterStore = useFilterStore();

  const allMarketDeals = ref<DealPreview[]>([]);
  const marketDeals = computed(() => filterStore.apply(allMarketDeals.value));

  const allCompanyDealDetails = ref<Record<DealId, DealDetail>>({});
  const companyDeals = computed<DealDetail[]>(() =>
    filterStore.apply(Object.values(allCompanyDealDetails.value))
  );

  const traded = ref<Record<number, number>>({});

  const setMarketDeals = (value: DealPreview[]) =>
    (allMarketDeals.value = value);

  const addMarketDeal = (deal: DealPreview) => {
    const dealExists = allMarketDeals.value.some((item) => item.id === deal.id);

    if (dealExists) {
      // Since deals are static, we only push to the store if it's not already there.
      return;
    }

    allMarketDeals.value.unshift(deal);
  };

  const addCompanyDeal = (dealDetail: DealDetail) => {
    const existing = allCompanyDealDetails.value[dealDetail.id];

    if (existing) {
      return;
    }

    allCompanyDealDetails.value[dealDetail.id] = dealDetail;
  };

  const updateCompanyDeals = (list: DealDetail[]) => {
    list.forEach((dealDetail) => addCompanyDeal(dealDetail));
  };

  const markAsTraded = (listing: ListingDetail) => {
    traded.value[listing.id] = listing.id;
  };

  const wasTraded = function (listing: ListingDetail) {
    return traded.value[listing.id] === listing.id;
  };

  function clear() {
    allMarketDeals.value = [];
    allCompanyDealDetails.value = {};
    traded.value = {};
  }

  return {
    allCompanyDealDetails,
    addCompanyDeal,
    addMarketDeal,
    clear,
    companyDeals,
    markAsTraded,
    marketDeals,
    setMarketDeals,
    updateCompanyDeals,
    wasTraded,
  };
});
