<template>
  <template v-if="showIndicator">
    <Button
      icon="fa-regular fa-circle-info"
      icon-position="right"
      variant="warning"
      class="ml-3 h-8"
      data-testid="testing-phase-indicator-button"
      @click="isOpen = true"
    >
      {{ $t(title) }}
    </Button>

    <Modal
      :is-open="isOpen"
      :has-footer="false"
      data-test-id="testing-phase-indicator-modal"
      @update:is-open="isOpen = $event"
    >
      <template #header>
        <h1 class="text-lg/none">{{ $t(title) }}</h1>
      </template>
      <template #body>
        <p class="max-w-xl">{{ $t(body) }}</p>
      </template>
    </Modal>
  </template>
</template>

<script setup lang="ts">
import Button from '@/components/Button.vue';
import Modal from '@/components/Modal.vue';
import { Market } from '@/types/market';
import { computed, ref } from 'vue';

const props = defineProps<{
  market: Market;
}>();

const isOpen = ref(false);

const showIndicator = computed(() => {
  return isTestingMarket.value || isBetaMarket.value;
});

const isTestingMarket = computed(() =>
  props.market?.name.toLowerCase().includes('testing')
);

const isBetaMarket = computed(() =>
  props.market?.name.toLowerCase().includes('beta')
);

const title = computed(() => {
  if (isTestingMarket.value) {
    return 'testing-period-indicator.test-title';
  }

  if (isBetaMarket.value) {
    return 'testing-period-indicator.beta-title';
  }

  return '';
});

const body = computed(() => {
  if (isTestingMarket.value) {
    return 'testing-period-indicator.test-message';
  }

  if (isBetaMarket.value) {
    return 'testing-period-indicator.beta-message';
  }

  return '';
});
</script>
