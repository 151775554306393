<template>
  <div
    class="flex max-w-72 items-center rounded-md px-3 py-1 text-sm/6"
    :class="{
      'bg-slate-200': !marketIsClosingSoon,
      'bg-red-500 text-white': marketIsClosingSoon,
    }"
  >
    <div
      class="mr-3 grid size-2.5 place-content-center rounded-full"
      :class="{
        'bg-white/25': marketIsClosingSoon,
        'bg-green-500/25': isMarketOpen && !marketIsClosingSoon,
        'bg-red-500/25': !isMarketOpen,
        'bg-yellow-500/25': isIdle && !marketIsClosingSoon,
        'animate-pulse': !isIdle,
      }"
    >
      <div
        class="size-1.5 rounded-full"
        :class="{
          'bg-white': marketIsClosingSoon,
          'bg-green-500': isMarketOpen && !marketIsClosingSoon,
          'bg-red-500': !isMarketOpen && !marketIsClosingSoon,
          'bg-yellow-500': isIdle && !marketIsClosingSoon,
        }"
      ></div>
    </div>
    <div v-if="!marketStore.activeMarket">
      <p>{{ $t('market-indicator.market-is-closed') }}</p>
    </div>
    <div v-else-if="isIdle">
      <p>{{ $t('market-indicator.app-is-idle') }}</p>
    </div>
    <div v-else class="mr-2 flex space-x-1">
      <p>{{ $t('market-indicator.market-is') }}</p>
      <p>
        {{
          isMarketOpen
            ? $t('market-indicator.open')
            : $t('market-indicator.opening-in')
        }}
      </p>
    </div>
    <div
      v-if="marketStore.activeMarket && !isIdle"
      class="ml-auto w-[75px] text-nowrap"
    >
      <span>{{ days }}</span>
      <span class="opacity-50">{{ $t('market-indicator.divider') }}</span>
      <NumberFlow :value="hours" />
      <span class="opacity-50">{{ $t('market-indicator.divider') }}</span>
      <NumberFlow :value="minutes" />
      <span class="opacity-50">{{ $t('market-indicator.divider') }}</span>
      <NumberFlow class="opacity-50" :value="seconds" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGetMarketDateReference, useIsMarketOpen } from '@/composables';
import { useMarketStore, useToastStore, useUserStore } from '@/stores';
import NumberFlow from '@number-flow/vue';
import { t } from 'i18next';
import { computed, onUnmounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const userStore = useUserStore();
const marketStore = useMarketStore();
marketStore.load();

const isMarketOpen = computed(() =>
  marketStore.activeMarket ? useIsMarketOpen(marketStore.activeMarket) : false
);
const dateOfReference = computed(() =>
  marketStore.activeMarket
    ? useGetMarketDateReference(marketStore.activeMarket)
    : new Date()
);
const isIdle = computed(() => userStore.isIdle);

const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);

const toast = useToastStore();
const marketIsClosingSoon = ref(false);

const router = useRouter();

const updateCountdown = () => {
  if (marketStore.activeMarket === null) return;

  const now = new Date();
  const timeUntilMarketEvent = dateOfReference.value.getTime() - now.getTime();

  // Log when 10 minutes are left and the market is open
  if (
    isMarketOpen.value &&
    timeUntilMarketEvent <= 600000 &&
    !marketIsClosingSoon.value
  ) {
    const minutes = Math.ceil(
      (timeUntilMarketEvent % (1000 * 60 * 60)) / (1000 * 60)
    );

    toast.warning({
      title: t('toast.market-is-closing'),
      message: t('toast.time-left', {
        time: t('common.minute', { count: minutes }),
      }),
      timeout: 0,
    });

    marketIsClosingSoon.value = true;
  }

  if (timeUntilMarketEvent <= 0 && marketIsClosingSoon.value) {
    router.push({ query: { market: 'closed' } });
  }

  if (timeUntilMarketEvent <= 0) {
    // Countdown is over
    clearInterval(interval);
    marketStore.load();
    marketIsClosingSoon.value = false;
    return;
  }

  days.value = Math.floor(timeUntilMarketEvent / (1000 * 60 * 60 * 24));
  hours.value = Math.floor(
    (timeUntilMarketEvent % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  minutes.value = Math.floor(
    (timeUntilMarketEvent % (1000 * 60 * 60)) / (1000 * 60)
  );
  seconds.value = Math.floor((timeUntilMarketEvent % (1000 * 60)) / 1000);
};

let interval = setInterval(updateCountdown, 1000);

const restart = () => {
  clearInterval(interval);
  interval = setInterval(updateCountdown, 1000);
  updateCountdown();
};

watch(
  () => marketStore.activeMarket,
  () => {
    restart();
  }
);

onUnmounted(() => clearInterval(interval));
</script>
