<template>
  <div class="flex flex-col gap-y-2 text-sm/4">
    <span class="text-vesper-neutral/60"> {{ tag }} </span>
    <span :class="variantClass"> {{ value }} </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  tag: string;
  value: string;
  variant?: 'standard' | 'big';
}>();

const variant = props.variant || 'standard';

const variantClass = computed(() => {
  return variant === 'big' ? 'text-xl/6 font-medium' : '';
});
</script>
