import { useEnrichedDetail } from '@/composables';
import { CompanyDealDetailCreated } from '@/notifications/types';
import {
  useDealModalStore,
  useDealStore,
  useLiquidityDealStore,
  useListingStore,
  useNegotiationStore,
} from '@/stores';
import { Volume } from '@/types';

const dealCreatedNotification = (event: CompanyDealDetailCreated) => {
  useDealModalStore().showModal(event.deal, useEnrichedDetail(event.listing));
};

const addToTradedDeals = (event: CompanyDealDetailCreated) => {
  useDealStore().markAsTraded(event.listing);
};

const removeNegotiationFromStore = (event: CompanyDealDetailCreated) => {
  useNegotiationStore().remove({
    negotiationId: event.negotiationId,
    listingId: event.listing.id,
  });
};

const removeActiveListingFromStore = (event: CompanyDealDetailCreated) => {
  useListingStore().removeFromActiveHistory(event.listing);
};

const addToYourDeals = (event: CompanyDealDetailCreated) => {
  useDealStore().addCompanyDeal(event.deal);
};

const queueLiquidityDeal = (event: CompanyDealDetailCreated) => {
  const listing = useEnrichedDetail(event.listing);

  if (!listing.isYourListing || !listing.isByLiquidityProvider) {
    return;
  }

  const availableVolume: Volume = {
    amount:
      Number(listing.initialVolume.amount) - Number(listing.volume.amount),
    unit: listing.volume.unit,
  };

  useLiquidityDealStore().enqueue({
    latestDelivery: listing.shipment.latestDelivery,
    productId: listing.productId,
    type: listing.type,
    status: listing.status,
    availableVolume: availableVolume,
  });
};

export default [
  dealCreatedNotification,
  addToTradedDeals,
  removeNegotiationFromStore,
  removeActiveListingFromStore,
  addToYourDeals,
  queueLiquidityDeal,
];
