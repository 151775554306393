<template>
  <Transition
    enter-from-class="opacity-0"
    leave-to-class="opacity-0"
    enter-active-class="transition duration-300"
    leave-active-class="transition duration-300"
  >
    <div
      v-if="panelCreateListingStore.panelIsOpen"
      class="absolute top-0 left-0 z-20 size-full bg-neutral-800/20"
      data-testid="panel-create-listing"
      @click="handleClosingPanel()"
    >
      <div
        class="flex h-full max-w-[420px] flex-col overflow-y-auto bg-white"
        @click.stop
      >
        <div
          class="sticky top-0 z-20 flex items-center border-b bg-white px-6 py-5"
        >
          <h2 class="font-medium" data-testid="panel-create-listing-title">
            {{ title }}
          </h2>
          <div
            class="hover:bg-vesper-zinc/30 ml-auto grid size-8 place-content-center rounded-md border transition-all hover:cursor-pointer"
            @click="handleClosingPanel()"
          >
            <Icon
              icon="fa-regular fa-xmark"
              fixed-width
              class="text-sm/none text-neutral-800/60"
            />
          </div>
        </div>
        <div class="p-10">
          <CreateListingForm
            v-if="form.product"
            v-show="!showPreview"
            ref="createListingFormRef"
            v-model:type="form.type"
            v-model:audience="form.audience"
            v-model:unit-price-value="form.unitPriceValue"
            v-model:volume-amount="form.volumeAmount"
            v-model:product="form.product"
            v-model:selected-factories="form.factories"
            v-model:selected-specs="form.specs"
            v-model:incoterm="form.incoterm"
            v-model:delivery-location="form.deliveryLocation"
            v-model:latest-delivery="form.delivery"
            :listing-types="listingTypes"
            :products="productStore.products"
            :factories="factories"
            :categorised-specs="categorisedSpecs"
            :shipment="shipment"
            :action="
              panelCreateListingStore.panelType === 'CREATE' ? 'CREATE' : 'EDIT'
            "
            @submit="submit()"
            @form-is-dirty="formIsDirty = $event"
          />

          <CreateListingPreviewCard
            v-if="form.product && showPreview"
            :form="form"
            :product="form.product"
          />
          <p class="mr-5 flex justify-end text-[10px] text-red-500">
            {{ errorMessage }}
          </p>
        </div>
        <div
          class="sticky bottom-0 z-10 mt-auto bg-linear-to-b from-transparent via-white to-white p-6"
        >
          <button
            v-if="!showPreview"
            :disabled="loading"
            form="create-listing-form"
            class="group bg-vesper-neutral relative flex w-full items-center rounded-md px-6 py-4 text-white"
          >
            <span class="text-sm/none">{{ $t('listing.preview') }}</span>
            <div class="ml-auto flex size-4 overflow-hidden">
              <Icon
                icon="fa-regular fa-arrow-right-long"
                fixed-width
                class="mr-1.5 -translate-x-6 duration-200 ease-out group-hover:translate-x-0"
              />
              <Icon
                icon="fa-regular fa-arrow-right-long"
                fixed-width
                class="-translate-x-6 duration-200 ease-out group-hover:translate-x-0"
              />
            </div>
            <div
              class="group-hover:bg-vesper-neutral/5 absolute inset-0 rounded-md"
            ></div>
          </button>
          <div v-if="showPreview" class="flex space-x-3">
            <button
              class="group relative flex w-full items-center rounded-md border bg-white px-6 py-4"
              @click="showPreview = false"
            >
              <span class="w-full text-sm/none">{{ $t('listing.edit') }}</span>
              <div
                class="group-hover:bg-vesper-neutral/5 absolute inset-0 rounded-md"
              ></div>
            </button>
            <button
              :disabled="loading"
              form="create-listing-form"
              class="group bg-vesper-green relative flex w-full items-center rounded-md px-6 py-4"
            >
              <span class="w-full text-sm/none">{{
                panelCreateListingStore.panelType === 'EDIT'
                  ? $t('listing-card.update-listing')
                  : $t('listing-card.place-listing')
              }}</span>
              <div class="ml-auto flex size-4 overflow-hidden">
                <Icon
                  icon="fa-regular fa-arrow-right-long"
                  fixed-width
                  class="mr-1.5 -translate-x-6 duration-200 ease-out group-hover:translate-x-0"
                />
                <Icon
                  icon="fa-regular fa-arrow-right-long"
                  fixed-width
                  class="-translate-x-6 duration-200 ease-out group-hover:translate-x-0"
                />
              </div>
              <div
                class="group-hover:bg-vesper-neutral/5 absolute inset-0 rounded-md"
              ></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>

  <ModalConfirm
    v-model:is-open="showClosingModal"
    :title="$t('listing.form.leaving-page')"
    :message="$t('listing.form.leaving-page-message')"
    has-cancel-button
    button-cancel-variant="light"
    button-confirm-variant="dark"
    :button-cancel-label="$t('listing.form.leaving-page-cancel')"
    :button-confirm-label="$t('listing.form.leaving-page-confirm')"
    :on-confirm="
      async () => {
        showClosingModal = false;
        showPreview = false;
        cleanForm();
        panelCreateListingStore.closePanel();
      }
    "
  />
</template>

<script setup lang="ts">
import { useApi } from '@/api';
import CreateListingForm from '@/components/CreateListingForm.vue';
import CreateListingPreviewCard from '@/components/CreateListingPreviewCard.vue';
import Icon from '@/components/Icon.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';
import {
  useGetIncotermForListingType,
  useGetListingTypeForCompany,
} from '@/composables';
import { listingAudiences } from '@/schemas/listing';
import {
  useApprovedFactoryStore,
  useCompanyFactoryStore,
  useListingStore,
  usePanelCreateListingStore,
  useProductStore,
  useShipmentStore,
  useUserStore,
} from '@/stores';
import { useI18n } from '@/translations';
import {
  CreateListingRequest,
  LatestDelivery,
  ListingDetail,
  ListingForm,
  ListingType,
  PatchListingRequest,
  ProductSpecOption,
  SelectedSpec,
  Specifications,
} from '@/types';
import { computedAsync, useAsyncState } from '@vueuse/core';
import { AxiosError } from 'axios';
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const panelCreateListingStore = usePanelCreateListingStore();
const showClosingModal = ref(false);
const formIsDirty = ref(false);
const showPreview = ref(false);

const title = computed(() => {
  const panelType = panelCreateListingStore.panelType;

  return t(`listing.panel-create-listing.${panelType}`, {
    id: panelCreateListingStore.duplicateData?.id,
  });
});

function handleClosingPanel() {
  if (formIsDirty.value === true) {
    showClosingModal.value = true;
  } else {
    showPreview.value = false;
    cleanForm();
    panelCreateListingStore.closePanel();
  }
}

const listingTypes: { value: ListingType; icon: string; label: string }[] = [
  {
    value: 'BID',
    icon: 'fa-regular fa-wallet',
    label: 'Bid',
  },
  {
    value: 'OFFER',
    icon: 'fa-duotone fa-solid fa-coins',
    label: 'Offer',
  },
];

const listingStore = useListingStore();
const productStore = useProductStore();

const isOptionDisabled = (listingType: ListingType) => {
  return !userStore.hasPermission(`MARKETPLACE/LISTING.${listingType}.CREATE`);
};

const getDefaultType = () => {
  return (
    listingTypes.find((typeOption) => !isOptionDisabled(typeOption.value))
      ?.value || listingTypes[0].value
  );
};

const userStore = useUserStore();
const companyFactoryStore = useCompanyFactoryStore();
const approvedFactoryStore = useApprovedFactoryStore();

const factories = computed(() => {
  return form.type === 'OFFER'
    ? companyFactoryStore.filterByProduct(form.product)
    : approvedFactoryStore.filterByProductAndAudience(
        form.product,
        form.audience
      );
});

const shipmentStore = useShipmentStore();

const shipment = computedAsync(() => shipmentStore.shipment, {
  locations: [],
  incoterms: [],
  dates: [],
});

const form: ListingForm = reactive({
  type: getDefaultType(),
  audience: listingAudiences[0],
  product: productStore.product,
  factories: [],
  unitPriceValue: undefined,
  volumeAmount: undefined,
  specs: [],
  incoterm: undefined,
  deliveryLocation: undefined,
  delivery: undefined,
  uuid: crypto.randomUUID(),
});

watch(
  () => panelCreateListingStore.duplicateData,
  (duplicatedListing) => {
    if (duplicatedListing) {
      populateForm(duplicatedListing);
    }
  }
);

function populateForm(duplicatedListing: ListingDetail) {
  form.type =
    useGetListingTypeForCompany(duplicatedListing.type, userStore.company) ||
    getDefaultType();
  form.audience = duplicatedListing.audience || listingAudiences[0];
  form.product =
    productStore.findById(duplicatedListing.productId) || productStore.product;
  form.factories = convertFactoryIdsToGroupedFactories(
    duplicatedListing.factories
  );
  form.unitPriceValue = Number(duplicatedListing.unitPrice.value);
  form.volumeAmount = Number(duplicatedListing.volume.amount);
  form.specs = convertListingSpecsToSelectedSpecs(
    duplicatedListing.specifications
  );
  form.incoterm = useGetIncotermForListingType(
    form.type,
    duplicatedListing.shipment.incoterm
  );
  form.deliveryLocation = shipmentStore.getLocationById(
    duplicatedListing.shipment.locationId
  );
  form.delivery = convertListingDeliveryToSelectedDelivery({
    timeSpan: duplicatedListing.shipment.timeSpan,
    latestDelivery: duplicatedListing.shipment.latestDelivery,
  });
}

const convertListingSpecsToSelectedSpecs = (
  specifications: Specifications
): SelectedSpec[] => {
  const selectedSpecs: SelectedSpec[] = [];

  Object.entries(specifications).forEach(([description, values]) => {
    // Find the matching spec
    const matchingSpec = Object.values(categorisedSpecs.value)
      .flat()
      .find((spec) => spec.description === description);

    if (!matchingSpec) {
      throw new Error(`No matching spec found for description: ${description}`);
    }

    // Find spec options inside the matching spec
    selectedSpecs.push({
      description,
      options: values.map((value) => {
        const option = matchingSpec.options.find(
          (option) => option.description === value
        );
        if (!option) {
          throw new Error(`No matching option found for description: ${value}`);
        }
        return { id: option.id, description: value };
      }),
    });
  });

  return selectedSpecs;
};

const convertFactoryIdsToGroupedFactories = (factoryIds: number[]) => {
  const filteredFactories = factories.value.filter((factory) =>
    factoryIds.includes(factory.id)
  );

  return shipment.value.locations.flatMap((location) => {
    const factoriesForLocation = filteredFactories.filter(
      (factory) => factory.locationId === location.id
    );

    return factoriesForLocation.map((factory) => ({
      groupKey: location.name,
      value: factory,
    }));
  });
};

const convertListingDeliveryToSelectedDelivery = (delivery: LatestDelivery) => {
  if (shipmentStore.isValidDeliveryDate(delivery)) {
    return delivery;
  }

  return undefined;
};

const categorisedSpecs = computedAsync(
  () => {
    return productStore.specsOf(form.product);
  },
  { QUALITY: [] }
);

function cleanForm() {
  form.type = getDefaultType();
  form.audience = listingAudiences[0];
  form.product = productStore.product;
  form.factories = [];
  form.unitPriceValue = undefined;
  form.volumeAmount = undefined;
  form.specs = [];
  form.incoterm = undefined;
  form.deliveryLocation = undefined;
  form.delivery = undefined;
  form.uuid = crypto.randomUUID();
}

watch(
  () => productStore.product,
  (newProduct) => {
    form.product = newProduct;
    form.factories = [];
    form.specs = [];
  }
);

watch(
  () => form.type,
  () => {
    if (panelCreateListingStore.duplicateData) return;
    form.incoterm = undefined;
    form.deliveryLocation = undefined;
  }
);

const api = useApi();
const router = useRouter();

const {
  error,
  execute: submit,
  isLoading: loading,
} = useAsyncState(
  async () => {
    if (!showPreview.value) {
      showPreview.value = true;
      return;
    }

    const listingSubmittedId =
      panelCreateListingStore.panelType === 'EDIT'
        ? await patchListing(form)
        : (await api.listing.create(toCreateRequest(form))).id;

    collectionSubmissionStats(listingSubmittedId);
    productStore.setActiveProduct(form.product);
    showPreview.value = false;
    panelCreateListingStore.closePanel();
    cleanForm();

    return router.push({
      name: 'listings',
      params: { productSlug: form.product.slug, id: listingSubmittedId },
    });
  },
  null,
  { immediate: false }
);

function toCreateRequest(form: ListingForm): CreateListingRequest {
  if (
    form.unitPriceValue === undefined ||
    form.volumeAmount === undefined ||
    form.incoterm === undefined ||
    form.deliveryLocation === undefined ||
    form.delivery === undefined
  ) {
    throw new Error('Missing required fields in form');
  }

  return {
    type: form.type,
    audience: form.audience,
    productId: form.product.id,
    unitPrice: {
      value: form.unitPriceValue,
      currency: 'EUR',
    },
    volume: {
      amount: form.volumeAmount,
      unit: 'MT',
    },
    productSpecificationOptionIds: form.specs
      // map selected specs into lists of options
      .map((selected: SelectedSpec) => selected.options)
      // reduce lists of options into a single list of options
      .reduce(
        (reduced: ProductSpecOption[], item: ProductSpecOption[]) =>
          reduced.concat(item),
        []
      )
      // map options into a list of ids
      .map((option: ProductSpecOption) => option.id),
    shipment: {
      incoterm: form.incoterm,
      locationId: form.deliveryLocation.id,
      delivery: form.delivery,
    },
    factories: form.factories.map((factory) => factory.value.id),
    uuid: form.uuid,
  };
}

function toPatchRequest(form: ListingForm): PatchListingRequest {
  if (
    !panelCreateListingStore.duplicateData ||
    form.unitPriceValue === undefined ||
    form.volumeAmount === undefined ||
    form.incoterm === undefined ||
    form.deliveryLocation === undefined ||
    form.delivery === undefined
  ) {
    throw new Error('Missing required fields in form');
  }

  return {
    id: panelCreateListingStore.duplicateData.id,
    type: form.type,
    audience: form.audience,
    unitPrice: {
      value: form.unitPriceValue,
      currency: 'EUR',
    },
    volume: {
      amount: form.volumeAmount,
      unit: 'MT',
    },
    productSpecificationOptionIds: form.specs
      // map selected specs into lists of options
      .map((selected: SelectedSpec) => selected.options)
      // reduce lists of options into a single list of options
      .reduce(
        (reduced: ProductSpecOption[], item: ProductSpecOption[]) =>
          reduced.concat(item),
        []
      )
      // map options into a list of ids
      .map((option: ProductSpecOption) => option.id),
    shipment: {
      incoterm: form.incoterm,
      locationId: form.deliveryLocation.id,
      delivery: form.delivery,
    },
    factories: form.factories.map((factory) => factory.value.id),
    uuid: form.uuid,
  };
}

async function patchListing(form: ListingForm) {
  await api.listing.patch(toPatchRequest(form));

  if (!panelCreateListingStore.duplicateData) {
    throw new Error('Missing duplicate data to get the listing ID');
  }

  const id = panelCreateListingStore.duplicateData.id;

  listingStore.removeDraft({ id: id });

  return id;
}

function collectionSubmissionStats(listingId: number) {
  switch (panelCreateListingStore.panelType) {
    case 'CREATE':
      // No need to collect any stats here
      break;
    case 'EDIT':
      api.stats.collectListingEdited(listingId);
      break;
    case 'DUPLICATE':
      api.stats.collectListingDuplicated(listingId);
      break;
  }
}

const errorMessage = computed(
  () => (error.value as AxiosError)?.response?.data ?? null
);
</script>
