import { ListingVolumeUpdated } from '@/notifications/types';
import { useListingStore } from '@/stores';

const updateListingVolume = (event: ListingVolumeUpdated) => {
  useListingStore().updateListingVolume(
    event.listingId,
    event.newVolume,
    event.availableVolume
  );
};

export default [updateListingVolume];
